
import { defineComponent, inject, nextTick, onMounted, ref } from "vue";

import { Question, store, storeKey } from "@/services/Store";
import { onBeforeRouteUpdate, useRoute, useRouter } from "vue-router";
import { DefaultBasicUtil, IBasicUtil } from "../interfaces/IBasicUtil";
import question from "@/services/question";
import Comp1 from "../components/questions/Comp1.vue"
import Comp2 from "../components/questions/Comp2.vue"
import Comp3 from "../components/questions/Comp3.vue"
import Comp4 from "../components/questions/Comp4.vue"
import Timer from "../components/parts/Timer.vue";
import TimerGage from "../components/parts/TimerGage.vue";
export default defineComponent({
  name: "Main",
  components: {
    Comp1,
    Comp2,
    Comp3,
    Comp4,
    TimerGage,
    Timer
  },
  data() {
    return {
      questions: [] as Question[],
      tabId: 0 as number,
      time: 0 as number
    }
  },

  setup() {
    const { data, isEnded } = inject(storeKey) as store;

    const timerGage = ref<typeof TimerGage | null>(null);

    const timerGageStop = () => {
      if (timerGage.value) {
        timerGage.value.stop();
      }
    };

    const timer = ref<typeof Timer | null>(null);

    const timerStop = () => {
      if (timer.value) {
        timer.value.stop();
      }
    };
    return {
      data,
      isEnded,
      timerGage,
      timerGageStop,
      timer,
      timerStop,
      basicbasicUtil: inject<IBasicUtil>(DefaultBasicUtil) as IBasicUtil,
    };
  },

  created() {
    const isAuth = this.basicbasicUtil.isAuth("1");
    if (!isAuth) this.$router.push("/auth");
    if (this.data.length == 0) {
      location.href = "/"
      return
    }
    const questions = this.data[Number(this.$route.params.id) - 1]
    this.questions = questions
    this.time = this.questions[0].time
    if (this.questions.length > 0) this.tabId = this.questions[0].id
  },

  methods: {
    next() {
      this.timerGageStop()
      this.timerStop() 
      if (this.data.length <= (Number(this.$route.params.id))) {
        this.$router.push("/result");
        return
      }
      
      this.$router.push({ name: "Main", params: { id: Number(this.$route.params.id) + 1 } });
    },

    end() {
      if (!confirm("受検を終了しますか？")) return
      this.timerGageStop()
      this.timerStop()
      this.$router.push("/result");
    },

    overTime() {
      this.next()
    }
  },
});
