import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-66c03768"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrap p-3 py-4" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "d-flex align-items-center" }
const _hoisted_4 = { class: "list-d" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "d-flex align-items-start" }
const _hoisted_7 = ["onChange", "checked"]
const _hoisted_8 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", {
      class: "mb-4",
      innerHTML: _ctx.question?.question2
    }, null, 8, _hoisted_2),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("ol", _hoisted_4, [
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(10, (n) => {
          return _createElementVNode("li", { key: n }, [
            (_ctx.question != null && _ctx.question['choice' + String(n)] && _ctx.question['choice' + String(n)] != '')
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createElementVNode("label", _hoisted_6, [
                    _createElementVNode("input", {
                      class: "radio",
                      type: "radio",
                      name: "select",
                      onChange: ($event: any) => (_ctx.answerExe(n)),
                      checked: _ctx.question['currentChoice'] == String(n)
                    }, null, 40, _hoisted_7),
                    _createElementVNode("span", null, _toDisplayString(_ctx.getIndex(n)), 1),
                    _createElementVNode("div", {
                      class: "",
                      innerHTML: _ctx.question['choice' + String(n)]
                    }, null, 8, _hoisted_8)
                  ])
                ]))
              : _createCommentVNode("", true)
          ])
        }), 64))
      ])
    ])
  ]))
}