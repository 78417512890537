import { IStorageService } from "@/interfaces/IStorageService";
import { Question } from "@/services/Store";
import question from './question'
import shiken from './shiken';
import { Shiken } from '@/services/Store';
import { IDataService } from "@/interfaces/IDataService";
export default class DataAccess implements IDataService {
    public getQuestion(): any {
        return JSON.parse(JSON.stringify(question))
    }

    public getShiken(id: number): Shiken | null {
        const shikens = this.getShikens()
        const ret = shikens.find(s => {
            return s.id == id
        })
        return ret ?? null
    }

    public getShikens(): Shiken[] {
        return shiken as unknown as Shiken[]
    }


    public getQuestionsFromAnswerType(shikenId: number, limit = 9999): Question[][] {
        let questions: Question[] = this.getQuestion()
        questions = questions.filter(q => {
            return q.shikenId == shikenId
        })
        questions.sort((a, b) => {
            if (a.parentNo !== b.parentNo) {
                return a.parentNo - b.parentNo;
            } else {
                return a.no - b.no;
            }
        });

        const groupedQuestions:Question[][] = [];
        let currentGroup: Question[] = [];
        let currentParentNo = -1;

        questions.forEach(question => {
            question.currentChoice = ""
            if (currentParentNo !== question.parentNo) {
                // 新しいグループを開始
                if (currentGroup.length > 0) {
                    groupedQuestions.push(currentGroup);
                }
                currentGroup = [];
                currentParentNo = question.parentNo;
            }
            currentGroup.push(question);
        });

        // 最後のグループを追加
        if (currentGroup.length > 0) {
            groupedQuestions.push(currentGroup);
        }

        return groupedQuestions.slice(0, limit);
    }

    shuffle<T>(array: T[]) {
        const out = Array.from(array);
        for (let i = out.length - 1; i > 0; i--) {
            const r = Math.floor(Math.random() * (i + 1));
            const tmp = out[i];
            out[i] = out[r];
            out[r] = tmp;
        }
        return out;
    }



    public getQuestionFromId(id: number) {
        const questions: Question[] = this.getQuestion()
        const shikens: Shiken[] = this.getShikens()
        const question = questions.find(q => {
            return q.id == id
        })
        if (question == undefined) return null
        const shiken = shikens.find(c => {
            return c.id == question?.shikenId
        })
        if (shiken) question!.shiken = shiken
        return question
    }
}
