import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2671c414"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrap p-3 py-4" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "d-flex align-items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", {
      class: "mb-4",
      innerHTML: _ctx.question?.question2
    }, null, 8, _hoisted_2),
    _createElementVNode("div", _hoisted_3, [
      _withDirectives(_createElementVNode("input", {
        class: "text-r mr-3 form-control",
        type: "text",
        onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onlyNumber($event))),
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.answer) = $event))
      }, null, 544), [
        [_vModelText, _ctx.answer]
      ])
    ])
  ]))
}