
import { defineComponent, inject, ref } from "vue";
import { store, storeKey } from "./services/Store";
import { DefaultBasicUtil, IBasicUtil } from "./interfaces/IBasicUtil";
import Cookies from 'js-cookie';
import Header from "./components/Header.vue"

export default defineComponent({
  name: "App",
  components: {
    Header
  },
  data () {
    return {
      isOver: false as boolean,
    }
  },
  setup() {
    const { isEnded} = inject(storeKey) as store;
    return {
      basicUtil: inject<IBasicUtil>(DefaultBasicUtil) as IBasicUtil,
      isEnded,
    };
  },
  
  methods: {

  }
})
