
import { defineComponent, inject, nextTick, onMounted } from "vue";



import { Question, store, storeKey } from "@/services/Store";
import { onBeforeRouteUpdate, useRoute, useRouter } from "vue-router";
import { DefaultBasicUtil, IBasicUtil } from "../../interfaces/IBasicUtil";

export default defineComponent({
  name: "Comp2",
  components: {
  },
  emits: ['answerEmit'],
  props: {
    questions: {
      type: Array as () => Question[][],
      required: true
    },
    qIndex: {
      type: Number,
      required: true
    },
    no: {
      type: Number,
      required: true
    },
  },
  data() {
    return {
      answer: [] as string[]|number[],
      question: null as Question|null
    }
  },

  setup(_, { emit }) {
    const { data, setData, isEnded } = inject(storeKey) as store;
    const answerEmit = (value: string) => {
      emit('answerEmit', value);
    };

    return {
      data,
      isEnded,
      setData,
      answerEmit,
      basicbasicUtil: inject<IBasicUtil>(DefaultBasicUtil) as IBasicUtil,
    };
  },

  created() {
    if (this.questions == undefined) return
    let noIndex = 0;
    if (this.no > 0) noIndex = this.no - 1
    this.question = this.data[this.qIndex][noIndex]
  },

  methods: {
    answerExe() {
      let noIndex = 0;
    if (this.no > 0) noIndex = this.no - 1
    
    this.answer.sort((a, b) => a - b);
    console.log(this.answer)
      this.data[this.qIndex][noIndex].currentChoice = this.answer.join("|")
      this.setData(this.data);
    },
    getIndex(i: number) {
      const arr = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K"]
      return arr[i - 1] + "　"
    }
  },
});
