import { createRouter, createWebHistory , RouteRecordRaw } from 'vue-router'
import Main from '../views/Main.vue'
import Result from '../views/Result.vue'
import Auth from '../views/Auth.vue'
import Home from '../views/Home.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/auth',
    name: 'Auth',
    component: Auth
  },
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/shiken/:shikenId/q/:id',
    name: 'Main',
    component: Main
  },
  {
    path: '/result',
    name: 'Result',
    component: Result
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve) => {
      requestAnimationFrame(() => {
        const element = document.getElementById('mainStage');
        if (element) {
          element.style.opacity = "0"
            element.scrollTop = 0; // スクロールを最上部に
            element.style.opacity = "1"
            resolve(savedPosition || { top: 0 });
        }
      });
    });
  }
});

export default router
