import { Question } from "@/services/Store";
import { InjectionKey, Ref } from "vue";

export const DefaultStorageUtil: InjectionKey<IStorageService> = Symbol ("storage");
export interface IStorageService {

  setData: (key: string, data: any) => void

  getData: (key:string) => any[]

  removeData: (key: string) => void

  getCurrentData: () => Question[] 

  setCurrentData: (data: Question[]) => void
  removeCurrentData: () => void
}