import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { IBasicUtil, DefaultBasicUtil } from './interfaces/IBasicUtil';
import { BasicUtil } from './services/BasicUtil';
import store, { storeKey } from "./services/Store";

const app = createApp(App)
  .use(router)
  .use(VueLoading)

const basicUtil = new BasicUtil()
app.provide(storeKey, store);
app.provide<IBasicUtil>(DefaultBasicUtil, basicUtil);
app.provide<IStorageService>(DefaultStorageUtil, new StorageService());
app.provide<IDataService>(DefaultDataService, new DataAccess());

// bootstrap
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.css';
import { DefaultStorageUtil, IStorageService } from './interfaces/IStorageService';
import StorageService from './services/StorageService';
import { DefaultDataService, IDataService } from './interfaces/IDataService';
import DataAccess from './services/DataAccess';

app.mount("#app");
