
export default [
    {
        "id": 63,
        "shikenId": 1,
        "parentNo": 58,
        "no": 1,
        "answerType": 1,
        "time": 120,
        "question1": "ある自然数について、次のような３通りの情報がある。<br><br>Ｐ　この数は６の倍数である<br>Ｑ　この数は偶数である<br>Ｒ　この数は３と４の公倍数である<br><br>以上の情報は、必ずしもすべてが信頼できるとは限らない。そこで、さまざまな場合を想定して推論がなされた。",
        "question2": "次の推論ア、イ、ウのうち、正しいものはどれか。Ａ～Ｈの中から１つ選びなさい。<br><br>ア　Ｐが正しければＱも必ず正しい<br>イ　Ｑが正しければＲも必ず正しい<br>ウ　Ｒが正しければＰも必ず正しい",
        "answer": "5",
        "score": 1,
        "choice1": "アだけ",
        "choice2": "イだけ",
        "choice3": "ウだけ",
        "choice4": "アとイの両方",
        "choice5": "アとウの両方",
        "choice6": "イとウの両方",
        "choice7": "アとイとウのすべて",
        "choice8": "正しい推論はない",
        "choice9": "",
        "choice10": ""
    }
]