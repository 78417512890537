
import { defineComponent, inject, nextTick, onMounted } from "vue";
import { Question, store, storeKey } from "@/services/Store";
import { onBeforeRouteUpdate, useRoute, useRouter } from "vue-router";
import { DefaultBasicUtil, IBasicUtil } from "../interfaces/IBasicUtil";

import {
  DefaultStorageUtil,
  IStorageService,
} from "@/interfaces/IStorageService";
import { DefaultDataService, IDataService } from "@/interfaces/IDataService";
// import Header from "@/components/Header.vue";
// import Footer from "@/components/Footer.vue";

export default defineComponent({
  name: "Main",
  components: {},
  data() {
    return {
      qas: [] as string[],
      answer: "",
      isAuth: false,
      isError: false,
      isCheck: false,
    };
  },

  setup() {
    const { data, setData, setRestTime, setAllTime } = inject(storeKey) as store;
    return {
      basicbasicUtil: inject<IBasicUtil>(DefaultBasicUtil) as IBasicUtil,
      storageService: inject<IStorageService>(
        DefaultStorageUtil
      ) as IStorageService,
      dataService: inject<IDataService>(
        DefaultDataService
      ) as IDataService,
      setData,
      setRestTime,
      setAllTime
    };
  },
  created() {
    this.isAuth = this.basicbasicUtil.isAuth("1");
    if (!this.isAuth) this.$router.push("/auth");
  },

  mounted() {
    this.basicbasicUtil.removeBasicSetting();
    this.storageService.removeCurrentData();
  },

  methods: {
    exec(shikenId: number) {
      const datas = this.dataService.getQuestionsFromAnswerType(
        Number(shikenId), 999
      );
      this.goToShiken(shikenId, datas);
    },
    goToShiken(shikenId: number, datas: Question[][]) {
      if (datas.length == 0) {
        alert("出題が存在しません");
        return;
      }
      this.setData([]);
      this.setData(datas);
      let allTime = 0
      datas.forEach(d => {
        if (d.length > 0) {
          allTime += d[0].time
        }
      })
      console.log(allTime)
      this.setRestTime(allTime);
      this.setAllTime(allTime)
      this.basicbasicUtil.setBasicSetting();
      this.$router.push(`/shiken/${shikenId}/q/1`);
    },
  },
});


