import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "wrap",
  style: {"height":"100vh"}
}
const _hoisted_2 = { class: "container h-100" }
const _hoisted_3 = { class: "h-100" }
const _hoisted_4 = { class: "" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_Header),
        _createElementVNode("div", _hoisted_4, [
          (_openBlock(), _createBlock(_component_router_view, {
            class: "h-100",
            key: _ctx.$route.fullPath
          }))
        ])
      ])
    ])
  ]))
}