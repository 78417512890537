
import { store, storeKey } from "@/services/Store";
import { computed, defineComponent, inject, ref } from "vue";

export default defineComponent({
  name: "Timer",
  props: {
    qIndex: {
      type: Number,
      required: true
    },
  },
  setup(props, context) {
    const { data, setRestTime, restTime, allTime } = inject(storeKey) as store;
    const firstAngle = (allTime.value - restTime.value) / allTime.value * 360
    const angle = ref<number>(firstAngle);

    const chartStyle = computed(() => ({
      background: `conic-gradient(
        #7DD486 ${angle.value}deg,
        #ddd ${angle.value}deg
      )`
    }));
    let sencondAngle = 0
    if (data.value.length > 0) sencondAngle = ((props.qIndex - 1)) / data.value.length * 360
    console.log(sencondAngle)
    const angle2 = ref<number>(sencondAngle);

    const chartStyle2 = computed(() => ({
      background: `conic-gradient(
        #D6D16F ${angle2.value}deg,
        #ddd ${angle2.value}deg
      )`
    }));
    return {
      angle,
      chartStyle,
      setRestTime,
      restTime,
      allTime,
      angle2,
      chartStyle2
    };
  },
  data() {
    return {
      isEnd: false as boolean,
      interval: null as any,
      goalDate: new Date() as any,
      timerStr: "" as string,
      rest: 0 as number,
    };
  },

  mounted() {
    this.rest = this.restTime;
    this.start();
  },

  methods: {
    start() {
      if (!this.restTime || this.restTime == 0) return;
      this.goalDate = new Date(+new Date() + this.restTime * 1000);

      const vm = this;
      this.interval = setInterval(function () {
        vm.countdown(vm.goalDate);
        vm.angle = (vm.allTime - vm.rest) / vm.allTime * 360
        if (vm.rest <= 0) {
          clearInterval(vm.interval);
          vm.isEnd = true;
        }
      }, 200);
    },

    countdown(due: Date) {
      var now = new Date();
      var rest = due.getTime() - now.getTime();
      this.rest = Math.floor(rest / 1000);
      this.setRestTime(Math.floor(rest / 1000));
    },

    stop() {
      clearInterval(this.interval);
    },


  },
});
