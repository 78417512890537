import { InjectionKey, Ref } from "vue";

export const DefaultBasicUtil: InjectionKey<IBasicUtil> = Symbol("util");
export interface IBasicUtil {
  
  /**
   * loading表示
   * component: VueComponent(this)
   * isFullScreen: 全画面表示か否か
   */
  showLoading: () => void;

  /**
   * loading非表示
   */
  hideLoading: () => void;
  setGradePass: (grade: string) => void
  isAuth: (grade: string) => boolean
  
  parseHtml: (content: string) => string

  onlyNumberMinus: (val: string) => string
  onlyNumberMinusWithoutComma: (val: string) => string

  resizeImage: (before:string, value:string) => void

  getQa3:() => string[]
  zentoHanNumber: (val: string) => string
  setBasicSetting:() => void
  removeBasicSetting:() => void
}