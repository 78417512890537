

import { defineComponent, inject, nextTick, onMounted } from "vue";



import { store, storeKey } from "@/services/Store";
import { onBeforeRouteUpdate, useRoute, useRouter } from "vue-router";
import { DefaultBasicUtil, IBasicUtil } from "../interfaces/IBasicUtil";
export default defineComponent({
  name: "Main",
  components: {
  },
  data() {
    return {
      qas: [] as string[],
      answer: "",
      alreadyAuth1: false,
      isError : false,
      isCheck: false
    }
  },
  
  setup() {
    return {
      basicbasicUtil: inject<IBasicUtil>(DefaultBasicUtil) as IBasicUtil,
    };
  },
  created() {
    this.alreadyAuth1 = this.basicbasicUtil.isAuth("1");
    if (this.alreadyAuth1) return this.$router.push("/")
  },

  methods: {
    answerQa() {

this.qas = this.basicbasicUtil.getQa3()
this.isError = false
if (!this.qas.includes(this.basicbasicUtil.zentoHanNumber(this.answer))) {
  this.isError = true
  return;
}
this.basicbasicUtil.setGradePass("1");

this.alreadyAuth1 = true;
location.href = "/";
}
  },
});
